import React from 'react';

interface props {
  children: React.ReactNode;
  className?: string;
}

export function Card({ children, className }: props) {
  return (
    <div
      className={`p-4 dark:bg-gray rounded-xl bg-dark-800 ${className} h-full`}
    >
      {children}
    </div>
  );
}
