import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Text, Heading, Card, Emoji } from '../../../components';
import { toCurrency, getPayRange } from '../../../lib';

interface props {
  data: {
    entities: {
      pay: any[];
      stateAverage: any;
      USAAverage: any;
    };
    status: {
      isLoading: boolean;
      error: any;
    };
  };
}

export function AveragePay({ data }: props) {
  const { pay } = data.entities;
  const { status } = data;
  const averagePay = getAveragePay(pay);
  const [topPay, bottomPay] = getPayRange(pay);

  const averagePaySummary = () => {
    if (status.isLoading) {
      return <Skeleton count={2} />;
    }

    if (averagePay) {
      return (
        <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
          <Emoji symbol="✨" label="sparkle" className="mr-1 text-xl" />
          We've crunched the numbers! The average pay in your area is roughly $
          {parseAveragePay(averagePay)} per hour or $
          {parseAnnualPay(averagePay)} a year. The top earners can expect to
          make ${topPay} while the lower earners can expect ${bottomPay}.
        </Text>
      );
    }

    return (
      <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
        We don't have enough data in your area to estimate your pay. Try
        searching for another location or increasing the search radius.
      </Text>
    );
  };

  const salaryItem = (pay: any) => {
    if (status.isLoading) {
      return (
        <div className="w-24">
          <Skeleton />
        </div>
      );
    }

    return (
      <div className="flex flex-row items-start">
        <Text className="font-bold text-gray-100 text-xl leading-none mr-1">
          $
        </Text>
        <Text className="font-bold text-gray-100 text-4xl lg:text-5xl leading-none">
          {pay}
        </Text>
      </div>
    );
  };

  return (
    <Card>
      <Heading className="mb-2">Average Pay in Your Area</Heading>
      {averagePaySummary()}

      <div className="flex flex-row mt-6">
        <div className="flex-1">
          <Text className="text-xs font-bold text-gray-600 leading-none mb-3">
            HOURLY PAY
          </Text>
          {salaryItem(parseAveragePay(averagePay))}
        </div>

        <div className="flex-1">
          <Text className="text-xs font-bold text-gray-600 leading-none mb-3">
            YEARLY PAY
          </Text>
          {salaryItem(parseAnnualPay(averagePay))}
        </div>
      </div>
    </Card>
  );
}

const getAveragePay = (pay: any[]): any => {
  const totalSalary = pay.reduce(
    (prev: any, current: any) => prev + current.averageSalary,
    0
  );

  const averagePay = totalSalary / pay.length;

  if (!averagePay) {
    return 0;
  }

  return averagePay;
};

const parseAveragePay = (averagePay: number) => {
  return toCurrency(averagePay, 1);
};

const parseAnnualPay = (averagePay: any) => {
  averagePay = averagePay.toFixed(0);

  if (averagePay > 0) {
    // @ts-ignore
    return (averagePay * 2080).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return '\u2013';
};
