import React from 'react';

type props = {
  children: React.ReactNode;
  pre?: React.ReactNode;
  size?: 'xs' | 'sm' | 'base' | 'lg';
  className?: string;
  roundedFull?: boolean;
  [x: string]: any;
};

export function Button({
  children,
  pre,
  size = 'base',
  roundedFull = false,
  className,
  ...rest
}: props) {
  const sizes = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2 py-1.5 text-sm font-medium',
    base: 'px-5 py-2 text-base font-medium',
    lg: 'px-5 py-2.5 text-lg font-medium',
  };

  const isRounded = roundedFull ? 'rounded-full' : null;

  return (
    <span
      className={`inline-flex items-center justify-center rounded-md cursor-pointer hover:opacity-90 ${sizes[size]} ${isRounded} ${className}`}
      {...rest}
    >
      {pre && <span className="mr-3">{pre}</span>}
      <span>{children}</span>
    </span>
  );
}

export function PrimaryButton({ children, className, ...rest }: props) {
  return (
    <Button
      className={`text-white bg-blue-700 hover:bg-blue-600 ${className}`}
      {...rest}
    >
      {children}
    </Button>
  );
}

export function LightButton({ children, className, ...rest }: props) {
  return (
    <Button
      className={`dark:text-100 dark:bg-dark-400 dark:hover:opacity-90 ${className}`}
      {...rest}
    >
      {children}
    </Button>
  );
}
