import React from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';
import Skeleton from 'react-loading-skeleton';

import { trades, store, toCurrency } from '../../../lib';
import { Text, Heading, Card } from '../../../components';
import * as States from '../../../components/States';

interface props {
  data: {
    entities: {
      pay: any[];
      stateAverage: any;
      USAAverage: any;
    };
    status: {
      isLoading: boolean;
      error: any;
    };
  };
}

export function StatePay({ data }: props) {
  const { state } = React.useContext(store);

  const { pay, stateAverage, USAAverage } = data.entities;
  const { status } = data;

  const currentCity = state.query.location.locality;
  const currentState = state.query.location.administrative_area_level_1;

  const statePay = toCurrency(stateAverage, 1);
  const USAPay = toCurrency(USAAverage, 1);
  const localPay = toCurrency(getAveragePay(pay), 1);

  // @ts-ignore
  const StateIcon = States[currentState];
  const currentTrade = trades.find((t) => t.id === state.query.trade);

  const showPayComparison = () => {
    if (status.isLoading) {
      return <Skeleton count={2} />;
    }

    if (localPay === '\u2013' && statePay !== '\u2013') {
      const text = `We don't have enough data for ${currentCity}, ${currentState}. But
          here is what you can expect to make based on state and nationwide
          averages`;

      return (
        <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
          {text}
        </Text>
      );
    }

    if (statePay === '\u2013') {
      const text = `We don't have enough data for ${currentCity} or ${currentState} state. But here is what you can expect to make based on nationwide averages`;

      return (
        <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
          {text}
        </Text>
      );
    }

    if (localPay > statePay && localPay > USAPay) {
      const text = `Pay in ${currentCity}, ${currentState} is competitive. You should expect to make more than state and national average for ${
        currentTrade?.name || 'your specialty'
      }`;

      return (
        <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
          {text}
        </Text>
      );
    }

    if (localPay < statePay && localPay < USAPay) {
      const text = `Pay in ${currentCity}, ${currentState} is not competitive. You can expect to make more in other parts of the state or country for ${
        currentTrade?.name || 'your specialty'
      }`;

      return (
        <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
          {text}
        </Text>
      );
    }

    if (localPay > statePay && localPay < USAPay) {
      const text = `Pay in ${currentCity}, ${currentState} is competitive when compared to the rest of the state. But overall, you can expect to make more in other parts of the country for ${
        currentTrade?.name || 'your specialty'
      }`;

      return (
        <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
          {text}
        </Text>
      );
    }

    if (localPay < statePay && localPay > USAPay) {
      const text = `Pay in ${currentCity}, ${currentState} is competitive compared to rest of the country. But you can expect to make more in other parts of ${currentState} state for ${
        currentTrade?.name || 'your specialty'
      }`;

      return (
        <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
          {text}
        </Text>
      );
    }

    const text = `Pay in ${currentCity}, ${currentState} is relatively competitive. You should expect to make roughly the same in other parts of the state and country for ${
      currentTrade?.name || 'your specialty'
    }`;

    return (
      <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-2">
        {text}
      </Text>
    );
  };

  const showStatWithArrow = (pay: any) => {
    if (status.isLoading) {
      return <Skeleton />;
    }

    if (pay > localPay) {
      return (
        <span className="whitespace-nowrap">
          <Text className="font-normal text-gray-300 mr-0.5 inline">$</Text>
          <Text className="font-bold text-gray-100 text-4xl lg:text-5xl leading-none inline">
            {toCurrency(pay, 1)}
          </Text>
          <ArrowDown
            size={18}
            strokeWidth={3}
            color="#E11D48"
            className="mr-1 inline"
          />
        </span>
      );
    }

    return (
      <span className="whitespace-nowrap">
        <Text className="font-normal text-gray-300 mr-0.5 inline">$</Text>
        <Text className="font-bold text-gray-100 text-4xl lg:text-5xl leading-none inline">
          {toCurrency(pay, 1)}
        </Text>
        <ArrowUp
          size={18}
          strokeWidth={3}
          color="#1D4ED8"
          className="mr-1 inline"
        />
      </span>
    );
  };

  return (
    <Card>
      <div className="mb-4">
        <Heading className="mb-2">How does it compare?</Heading>
        {showPayComparison()}
      </div>

      <div className="flex flex-row flex-wrap">
        <div className="flex flex-col lg:flex-row flex-1">
          <StateIcon className="state-icon mx-auto lg:mx-0 lg:mr-2" />

          <div className="mx-auto lg:mx-0">
            <Text className="text-xs font-bold text-gray-600 leading-none mb-2">
              PAY IN {currentState} STATE
            </Text>

            {showStatWithArrow(stateAverage)}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row flex-1">
          <States.USA className="state-icon mx-auto lg:mx-0 lg:mr-2" />

          <div className="mx-auto lg:mx-0">
            <Text className="text-xs font-bold text-gray-600 leading-none mb-2">
              PAY IN USA
            </Text>

            {showStatWithArrow(USAAverage)}
          </div>
        </div>
      </div>
    </Card>
  );
}

const getAveragePay = (pay: any[]): any => {
  const totalSalary = pay.reduce(
    (prev: any, current: any) => prev + current.averageSalary,
    0
  );

  const averagePay = totalSalary / pay.length;

  if (!averagePay) {
    return 0;
  }

  return averagePay;
};
