import firebase from 'firebase/app';
import * as geofirex from 'geofirex';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: 'skilledpay.firebaseapp.com',
  projectId: 'skilledpay',
  storageBucket: 'skilledpay.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

export const firestore = firebase.firestore();
// @ts-ignore
export const geo = geofirex.init(firebase);
