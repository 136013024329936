import React from 'react';

import { Pay } from './Pay';
import { usePayData } from './usePayData';

export function PayContainer({ ...props }) {
  const data = usePayData();

  return <Pay data={data} {...props} />;
}
