type num = number | string;
type decimals = 1 | 2;

export const toCurrency = (num: num, decimals: decimals) => {
  if (num && !decimals) {
    // @ts-ignore
    return parseInt(num);
  }

  if (num && decimals === 1) {
    // @ts-ignore
    return parseFloat(num).toFixed(1);
  }

  if (num && decimals === 2) {
    // @ts-ignore
    return parseFloat(num).toFixed(2);
  }

  return '\u2013';
};
