import React from 'react';
import { Menu } from '@headlessui/react';

type props = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  [x: string]: any;
};

export function Dropdown({ trigger, children, className, ...rest }: props) {
  return (
    <div className={`relative inline-block text-left ${className}`} {...rest}>
      <Menu>
        <Menu.Button>{trigger}</Menu.Button>

        {children}
      </Menu>
    </div>
  );
}
