type Trade = {
  id: string;
  name: string;
  industrial: boolean;
  construction: boolean;
  imageURL: string;
};

export const trades: Trade[] = [
  {
    id: '0G95fx8juKOykePz3sxA',
    name: 'Project Engineer',
    industrial: false,
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fproject_engineer.jpeg?alt=media&token=daec2510-3295-40c0-b3b7-43127f6c3efe',
  },
  {
    id: '1ziBu3pUZ5ctZXBcUL4a',
    construction: false,
    name: 'Environmental Engineer',
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fenvironmental_engineer.jpg?alt=media&token=def52739-9299-44c8-94fd-f11cd37eb836',
  },
  {
    id: '2Wlz9s1uvG8MxLQ65vzj',
    construction: false,
    name: 'Machinery Mechanic',
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fmachinery_mechanic.jpg?alt=media&token=81ecf405-fd6b-4cd2-a91a-adbf5c6e0d15',
  },
  {
    id: '2gTfKRWCd70y1JfQqsAm',
    industrial: true,
    construction: false,
    name: 'Civil Engineer',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fcivil_engineer.jpeg?alt=media&token=b6d4675f-c9ad-4214-97b3-f61d736a0f29',
  },
  {
    id: '34tC44CFWaBcLrlrQuuz',
    construction: true,
    industrial: false,
    name: 'General & Multi-Trade Builder',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fgeneral.jpg?alt=media&token=809b9f7d-cb40-463e-aa6c-42d143da3f63',
  },
  {
    id: '3HPNj2W4xeYj44lRfgBE',
    industrial: true,
    construction: true,
    name: 'Maintenance & Repair',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fmaintenance_repairs.jpeg?alt=media&token=9dda37ef-4cb3-42fc-b99e-b5e431bc2b0f',
  },
  {
    id: '43nbG06YoLbBVhqIOqxM',
    name: 'Design & Modeling',
    construction: false,
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fmodel_maker.jpg?alt=media&token=80f890b4-096d-4fdb-8631-3b919990d557',
  },
  {
    id: '4mIXp1VxNh49NE4WTSNg',
    industrial: false,
    name: 'Construction Inspector',
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fconstruction_inspector.jpeg?alt=media&token=3fd82910-9a98-4da6-bc03-20c8393bdea8',
  },
  {
    id: '5e03bJTG7JDOU4eauopH',
    name: 'Warehouse Operator',
    industrial: true,
    construction: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fwarehouse_operator.jpeg?alt=media&token=1afcf41b-c009-4539-982a-c0614a4dd265',
  },
  {
    id: '7bn5eQruPaRo71VZUwGT',
    construction: false,
    name: 'Mechanical Engineer',
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fmechanical_engineer.jpeg?alt=media&token=de1e6ec9-9574-4f48-b10a-89b64190b08a',
  },
  {
    id: '82rB8NQ426iX3ZlhEyEz',
    industrial: false,
    construction: true,
    name: 'Crane & Tower Operator',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fcrane_operator.jpg?alt=media&token=077f681a-f792-40ed-a961-3af10ffed1a4',
  },
  {
    id: '8aom0F6WxyESwJ63I23u',
    construction: false,
    industrial: true,
    name: 'Automotive Mechanic',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fautomotive_mechanic.jpeg?alt=media&token=5cc447d9-8e10-45bd-9146-9fc1cf62ab15',
  },
  {
    id: '9Tk9pifbx9iGkGXPZYEP',
    industrial: true,
    construction: true,
    name: 'Materials Handler',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fmaterials_handle.jpeg?alt=media&token=9f556002-8472-449f-8a07-83d75460d59f',
  },
  {
    id: '9d78J5vvJiIcf4TQhvmZ',
    construction: true,
    name: 'Assembler & Shop Fabricator',
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fassembly.jpeg?alt=media&token=59a52b49-2eed-4ad7-9610-be4cfdb196e3',
  },
  {
    id: '9rw25cM026305FDtytwB',
    name: 'Machinist / Operator',
    construction: false,
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fmachinist.jpg?alt=media&token=6b050b4a-e49d-45d4-8a1e-b1797bb98358',
  },
  {
    id: 'DBLDEa27YnulV26KF418',
    industrial: false,
    name: 'Fire Protection',
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Ffire_protection.jpeg?alt=media&token=4bfe1f05-9d3b-4271-88e2-af85c73d4a1a',
  },
  {
    id: 'Fjwmct6FyAyuydgRP006',
    construction: false,
    industrial: true,
    name: 'Process & Automation',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fautomation.jpeg?alt=media&token=23d6c485-d7a9-4bd8-b2e3-437e3b18ec54',
  },
  {
    id: 'HB3RqGalCS5Ey4y1lZL2',
    name: 'Sheet Metal Worker',
    construction: true,
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fsheetmetal_worker.jpg?alt=media&token=4bbabd5d-1009-46b1-b741-a52b57d94894',
  },
  {
    id: 'J9E87n1tjv8UChiSQ97k',
    industrial: true,
    construction: true,
    name: 'Dispatch & Transportation',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fdispatch_transportation.jpeg?alt=media&token=70656e23-4db1-48be-8814-b0146048432c',
  },
  {
    id: 'JZHIWxu4vBmXRecDOgW5',
    industrial: true,
    name: 'Utility Operator',
    construction: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Futility_operator.jpeg?alt=media&token=8d5d6742-86de-49bb-83d9-0a0aa47ed7d0',
  },
  {
    id: 'L9p9rD9CNqO4roSsRTvp',
    industrial: false,
    construction: true,
    name: 'Custodial & Porter',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fcustodial.jpeg?alt=media&token=f33c07ce-69cd-499a-b6b4-729c8a3a5124',
  },
  {
    id: 'MVKRypyEqNhBJOIdoNW8',
    industrial: true,
    construction: false,
    name: 'CNC Operator & Programmer',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fcnc_operator.jpeg?alt=media&token=40111808-391b-4ec8-9970-7f7690d8e7f3',
  },
  {
    id: 'PgUitxDG8uwUAmGkH4FR',
    construction: false,
    name: 'Water Treatment Operator',
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fwater_system_operator.jpg?alt=media&token=f29367c0-2dc4-47e5-9d99-ddb6222e23fb',
  },
  {
    id: 'PgeMpuC3pCcoGFIF3P4o',
    construction: false,
    name: 'General Worker & Operator',
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fgeneral_worker.jpeg?alt=media&token=b1fe4df8-10df-455c-bfcc-28e19e0c91e5',
  },
  {
    id: 'Pjt4maAJumFMzMXyeMHK',
    name: 'Electrical Engineer',
    construction: false,
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Felectric_engineer.jpeg?alt=media&token=b8edc997-9336-448c-a53e-01a8ad007678',
  },
  {
    id: 'PkGG6nq97jSZkkgygyVq',
    name: 'Rigger',
    industrial: true,
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Friggers.jpg?alt=media&token=eea38cc8-11b0-4af7-996c-836f5980d948',
  },
  {
    id: 'QOVgNJ4TNyi5peHXoHiR',
    industrial: false,
    name: 'Glazier',
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fglazier.jpg?alt=media&token=fd17b264-f14e-4e13-b909-8ce2ba0550e8',
  },
  {
    id: 'RDMmrCHwqjGRJJYHhRjH',
    name: 'Electrician',
    industrial: false,
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Felectrician.jpg?alt=media&token=0f6d1968-7410-4efe-9dfc-fb488162262a',
  },
  {
    id: 'RuTJvmwRDfwaUEolirS0',
    name: 'Facilities Technician',
    industrial: true,
    construction: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Ffacilities_technician.jpeg?alt=media&token=eea54b4c-3db6-48e7-ab60-15d2f8bc5f91',
  },
  {
    id: 'StluxG8yktqKO0ZKxKDA',
    industrial: true,
    name: 'Auto Body Technician',
    construction: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fauto_body.jpeg?alt=media&token=e1bbc41e-042f-49be-8a5c-1cc2b0683a78',
  },
  {
    id: 'UNdMsWsWm1X0I6edJpJL',
    industrial: false,
    construction: true,
    name: 'Safety & Code Inspector',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fsafety_inspector.jpeg?alt=media&token=db5ab9d0-88f8-457c-b255-c248e226e454',
  },
  {
    id: 'UwyxEW7QKQRQdSmWiJnF',
    industrial: false,
    construction: true,
    name: 'Heavy Equipment Operator',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fheavy_equipment_operator.jpg?alt=media&token=6dd149df-6f52-4b0f-aa25-108b6adfeeed',
  },
  {
    id: 'WuvasMu2WpIMfaeq3tUg',
    construction: true,
    name: 'HVAC',
    industrial: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fhvac.jpg?alt=media&token=ff497eb3-6e7c-4004-a783-bdad68fe0090',
  },
  {
    id: 'X1yq33CouzYMZbuR40Fo',
    name: 'Elevator Mechanic',
    industrial: false,
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Felevator_installer.jpg?alt=media&token=79042066-a57d-45f7-9e32-a00f3f3425f5',
  },
  {
    id: 'Z56rpE7FvhySRbhQRAtV',
    construction: true,
    industrial: false,
    name: 'Estimator',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Festimator.jpeg?alt=media&token=f2539a76-8936-48b3-8998-0fa4493416c4',
  },
  {
    id: 'ZDCc6p50x1ffJON4DqYc',
    industrial: true,
    name: 'Boilermaker',
    construction: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fboilermaker.jpg?alt=media&token=17c4799c-7c89-43ad-852a-1d2f55f77037',
  },
  {
    id: 'aYZICSYDgl02DaMYBhvT',
    name: 'Tool & Die Maker',
    industrial: true,
    construction: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Ftool_maker.jpg?alt=media&token=47776d5b-e10e-4708-ba61-55725f6f3f24',
  },
  {
    id: 'af0HWwey6ewfTzqrpqVp',
    industrial: false,
    construction: true,
    name: 'Carpenter',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fcarpenter.jpg?alt=media&token=7d14ffa3-93b1-453e-a204-71f454e09ae3',
  },
  {
    id: 'bXjZXGoTK9bOgNUKSCFC',
    construction: false,
    name: 'Stationary Engineer & Operator',
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fstationary_engineer.jpg?alt=media&token=7fc95db2-551b-4f11-a461-17e7b6d4a253',
  },
  {
    id: 'cpfgZX7rIUQH1FV7zibQ',
    construction: false,
    industrial: true,
    name: 'Pattern Maker',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fpattern_maker.jpg?alt=media&token=bc886df0-eb4f-4484-8983-07e7b816522f',
  },
  {
    id: 'cqkt4srJaLbxQj3jmHt7',
    industrial: true,
    name: 'Quality Control & Inspection',
    construction: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fquality_control.jpeg?alt=media&token=6f61aabf-196f-43b5-8a2b-3a9b234e6272',
  },
  {
    id: 'dmXK24WK0PW12oGRjh6R',
    industrial: false,
    name: 'VDC / Design',
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fvdc.jpeg?alt=media&token=bb5d0e78-ef8f-4a6f-b829-4cf44fc72d66',
  },
  {
    id: 'eEfLxDrh4rEekIy2Av5j',
    construction: true,
    name: 'Concrete Finisher',
    industrial: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fconcrete_worker.jpg?alt=media&token=2617f861-ac88-4a21-90b6-2a01b6c5454a',
  },
  {
    id: 'i8GMAROpOXdTryYyszI0',
    name: 'Aviation Mechanic',
    construction: false,
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Faviation_mechanic.jpeg?alt=media&token=00558b1c-b8ed-474a-837a-a335243ef83d',
  },
  {
    id: 'ilsafgi1ZqD2IntPypG0',
    name: 'Steel & Iron Worker',
    industrial: false,
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Firon_worker.jpg?alt=media&token=f2d5b8ed-a3af-49fb-8810-4fbc740c1751',
  },
  {
    id: 'jMIJR3lqoLqaVM7rjA1h',
    construction: false,
    industrial: true,
    name: 'Field Service Technician',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Ffield_service_technician.jpeg?alt=media&token=1d30168c-dd15-4682-8816-0511ea056729',
  },
  {
    id: 'jWepWvqxIAZoM5yvmGly',
    name: 'Project Manager',
    construction: true,
    industrial: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fproject_manager.jpeg?alt=media&token=db9cfe59-c077-4b33-9604-773d22a474bc',
  },
  {
    id: 'jYqZxle5zz84qO5j8dGj',
    construction: false,
    industrial: true,
    name: 'Industrial Engineer',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Findustrial_engineer.jpeg?alt=media&token=34f17cc7-319f-439a-a55c-879269b85a01',
  },
  {
    id: 'jkv6Zk4GKt9g4ts1vwig',
    construction: false,
    industrial: true,
    name: 'Millwright',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fmillwright.jpg?alt=media&token=d6168458-d52d-47ea-b76f-d4f82a19a3cf',
  },
  {
    id: 'jvSxKeZ6RRaXsrcpKH9F',
    industrial: true,
    construction: false,
    name: 'Electrical Technician',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Felectric_engineer.jpeg?alt=media&token=b8edc997-9336-448c-a53e-01a8ad007678',
  },
  {
    id: 'kh0lDW5o3ZG3MA7v1uKk',
    name: 'Painter & Plasterer',
    industrial: false,
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fpainter.jpg?alt=media&token=6f868ee1-a271-4327-acc4-ea0556fb08e0',
  },
  {
    id: 'kzTMlcKkNH6Zf0cg2w2W',
    construction: true,
    name: 'Plumber',
    industrial: false,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fplumber.jpg?alt=media&token=af92323f-1794-4e0d-a7e9-c0d29c4b94ad',
  },
  {
    id: 'mdi6a3mUWkzh4bsOGZ9q',
    construction: true,
    industrial: false,
    name: 'Mason',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fmason.jpg?alt=media&token=b938ad8d-aa60-4294-bdad-5805210e5798',
  },
  {
    id: 'myfTwPm3kGLxUehQ5fz9',
    industrial: true,
    construction: true,
    name: 'Pipefitter',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fpipefitter.jpg?alt=media&token=268baa77-e11a-462d-b86e-425103cb4795',
  },
  {
    id: 'nTDxq9v06zYQpQ17b1mn',
    name: 'Diesel Mechanic',
    construction: false,
    industrial: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fdiesel_technician.jpeg?alt=media&token=6b5b1e4f-a84c-4ce7-92dd-dd5a71f07748',
  },
  {
    id: 'qiI1fm9BZqWezVPkbGfB',
    industrial: false,
    construction: true,
    name: 'Superintedent',
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fsuperintendent.jpeg?alt=media&token=b6918bb8-ea53-4940-86b6-6ac0a9e5da38',
  },
  {
    id: 'wzVmdunesqWDwUGyjchH',
    name: 'Welder & Solderer',
    industrial: true,
    construction: true,
    imageURL:
      'https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Ftrade_images%2Fwelder.jpg?alt=media&token=ba96617a-6d3f-4ac0-a3cc-0956ca777887',
  },
];
