export function Emoji({ className, label, symbol, ...rest }: any) {
  return (
    <span
      className={`emoji ${className}`}
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
      {...rest}
    >
      {symbol}
    </span>
  );
}
