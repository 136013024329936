import React from 'react';
import { get } from 'geofirex';

import { store } from '../../lib/store';
import { geo, firestore } from '../../lib/firebase';

interface payData {
  pay: any[];
  stateAverage: any;
  USAAverage: any;
}

interface status {
  isLoading: boolean;
  error: any;
}

export const usePayData = () => {
  const initialData: payData = {
    pay: [],
    stateAverage: 0,
    USAAverage: 0,
  };

  const initialStatus: status = {
    isLoading: false,
    error: null,
  };

  const { state } = React.useContext(store);
  const [status, setStatus] = React.useState(initialStatus);
  const [data, setData] = React.useState(initialData);

  const setLoadingStatus = () => {
    setStatus({
      isLoading: true,
      error: null,
    });
  };

  const setDoneStatus = () => {
    setStatus({
      isLoading: false,
      error: null,
    });
  };

  const setErrorStatus = (err: any) => {
    setStatus({
      isLoading: false,
      error: err.message,
    });
  };

  const setSuccessData = (data: payData) => {
    setData(data);
  };

  const setNullData = () => {
    setData({
      pay: [],
      stateAverage: 0,
      USAAverage: 0,
    });
  };

  React.useEffect(() => {
    // Toggle state and prepare to fetch data
    setLoadingStatus();

    const fetchData = async () => {
      const radiusKM = state.query.radius * 1.6;

      try {
        const center = geo.point(
          parseFloat(state.query.location.lat),
          parseFloat(state.query.location.long)
        );

        // Get salaries
        const queryRef = firestore
          .collection('salaries')
          .where('trades', 'array-contains', state.query.trade);
        const query = geo.query(queryRef).within(center, radiusKM, 'point');
        const pay = await get(query);

        // Get state and USA averages
        const stateTotals = await firestore
          .collection('stateTradePay')
          .doc(
            `${state.query.location.administrative_area_level_1}-${state.query.trade}`
          )
          .get();

        const USATotals = await firestore
          .collection('stateTradePay')
          .doc(`USA-${state.query.trade}`)
          .get();

        const stateData = stateTotals.data();
        const USAData = USATotals.data();

        setSuccessData({
          pay,
          stateAverage: stateData?.totalPay / stateData?.count,
          USAAverage: USAData?.totalPay / USAData?.count,
        });

        setDoneStatus();
      } catch (err) {
        setNullData();

        setErrorStatus(err);
      }
    };

    fetchData();
  }, [state.query]);

  return { status, entities: data };
};
