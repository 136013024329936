import { toCurrency } from './toCurrency';

type pay = {
  averageSalary: number;
  [key: string]: any;
}[];

export const getPayRange = (pay: pay) => {
  let lowerBound = 0;
  let topBound = 1000;

  for (let i = 0; i < pay.length; i++) {
    const salary = pay[i].averageSalary;

    if (salary < topBound) {
      topBound = salary;
    }
    if (salary > lowerBound) {
      lowerBound = salary;
    }
  }

  const highestPay = toCurrency(topBound, 1);
  const lowestPay = toCurrency(lowerBound, 1);

  return [lowestPay, highestPay];
};
