import React from 'react';

import { Link } from 'react-router-dom';
import logo from '../../lib/assets/logo/skilledpay_logo_darkmode_md.png';
import { Container } from '../../components';

export function HomeNav() {
  return (
    <nav className="top-0 left-0 bg-white dark:bg-dark-900 fixed z-10 w-full shadow-sm">
      <Container>
        <div
          className="mx-auto flex items-center justify-between h-16"
          style={{ maxWidth: '1280px' }}
        >
          <div className="flex items-center">
            <Link to="/" className="inline">
              <img className="h-5" src={logo} alt="Workflow" />
            </Link>
          </div>
        </div>
      </Container>
    </nav>
  );
}
