import React from 'react';
import { MapPin, Compass, ChevronDown } from 'react-feather';
import { Link, Switch, Route } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { store, trades } from '../../lib';
import { useRouter } from '../../hooks';
import {
  Dropdown,
  Container,
  Heading,
  Text,
  LightButton,
} from '../../components';
import { Overview } from './Overview';
import { Details } from './Details';

interface props {
  data: {
    entities: {
      pay: any[];
      stateAverage: any;
      USAAverage: any;
    };
    status: {
      isLoading: boolean;
      error: any;
    };
  };
}

export function Pay({ data }: props) {
  const { state, dispatch } = React.useContext(store);
  const { getPath } = useRouter();
  const currentPath = getPath();
  const currentTrade = trades.find((t) => t.id === state.query.trade);

  const selectRadius = (radius: number) =>
    dispatch({ type: 'query/setRadius', payload: radius });

  const activeTabStyles =
    'text-3xl lg:text-4xl font-medium text-gray-100 leading-none py-4 border-b-2 border-blue-600';
  const tabStyles =
    'text-3xl lg:text-4xl font-medium text-gray-500 leading-none py-4';

  const location = state.query.location.formatted_address;

  return (
    <>
      <div
        className="h-48 lg:h-60 bg-blue-100"
        style={{
          backgroundImage: `url("https://maps.googleapis.com/maps/api/staticmap?center=${state.query.location.lat},${state.query.location.long}&zoom=10&scale=2&size=1200x320&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_API}&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x4F46E5%7C")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          boxShadow: 'inset 0 0 0 2000px rgba(20, 24, 35, 0.7)',
        }}
      >
        <Container>
          <LightButton
            size="sm"
            className="mt-6"
            pre={<MapPin size={18} strokeWidth={1.5} />}
            onClick={() =>
              dispatch({
                type: 'drawer/open',
                payload: { isOpen: 'query/location' },
              })
            }
          >
            {location.length > 25
              ? `${location.substring(0, 20)}...`
              : location}
          </LightButton>

          <Dropdown
            trigger={
              <LightButton
                size="sm"
                pre={<Compass size={18} strokeWidth={1.5} />}
              >
                {state.query.radius} mi.
              </LightButton>
            }
            className="mt-6 float-right"
          >
            <Menu.Items className="absolute right-0 mt-2 origin-top-right bg-dark-100 rounded-md shadow-lg outline-none w-max z-10">
              <Menu.Item
                className="px-4 py-3 my-1 hover:bg-blue-600"
                onClick={() => selectRadius(25)}
              >
                <Heading clickable>25 miles</Heading>
              </Menu.Item>
              <Menu.Item
                className="px-4 py-3 my-1 hover:bg-blue-600"
                onClick={() => selectRadius(50)}
              >
                <Heading clickable>50 miles</Heading>
              </Menu.Item>
              <Menu.Item
                className="px-4 py-3 my-1 hover:bg-blue-600"
                onClick={() => selectRadius(75)}
              >
                <Heading clickable>75 miles</Heading>
              </Menu.Item>
              <Menu.Item
                className="px-4 py-3 my-1 hover:bg-blue-600"
                onClick={() => selectRadius(100)}
              >
                <Heading clickable>100 miles</Heading>
              </Menu.Item>
            </Menu.Items>
          </Dropdown>
        </Container>
      </div>

      <Container>
        <div className="flex flex-row relative -top-8 -mb-4 z-1">
          <div
            className="h-20 w-20 bg-blue-100 relative flex-shrink-0 rounded-xl"
            style={{
              backgroundImage: `url(${currentTrade?.imageURL})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
            }}
            onClick={() =>
              dispatch({
                type: 'drawer/open',
                payload: { isOpen: 'query/trade' },
              })
            }
          ></div>

          <span
            className="cursor-pointer hover:opacity-80 ml-4 mt-auto mb-2 whitespace-normal"
            onClick={() =>
              dispatch({
                type: 'drawer/open',
                payload: { isOpen: 'query/trade' },
              })
            }
          >
            <Text
              clickable
              className="inline-block text-md lg:text-base font-medium text-gray-800 dark:text-gray-300 whitespace-normal"
            >
              {currentTrade?.name}
            </Text>
            <ChevronDown
              size={16}
              strokeWidth={2}
              color="#9CA3AF"
              className="ml-1 inline"
            />
          </span>
        </div>

        <div className="flex flex-row items-center border-b border-dark-600">
          <Link to={`/pay`}>
            <Text
              clickable
              className={currentPath === '/pay' ? activeTabStyles : tabStyles}
            >
              Overview
            </Text>
          </Link>

          <div className="mx-2" />

          <Link to={`/pay/details`}>
            <Text
              clickable
              className={
                currentPath === '/pay/details' ? activeTabStyles : tabStyles
              }
            >
              Details
            </Text>
          </Link>
        </div>

        {/* Main Content */}
        <div className="py-8">
          <Switch>
            <Route path="/pay/details">
              <Details data={data} />
            </Route>

            <Route path="/pay">
              <Overview data={data} />
            </Route>
          </Switch>
        </div>
      </Container>
    </>
  );
}
