import React from 'react';

import { PayByExpChart } from '../Charts';
import { Heading, Text, Emoji } from '../../../components';
import { AveragePay } from './AveragePay';
import { StatePay } from './StatePay';

// @ts-ignore
interface props {
  data: {
    entities: {
      pay: any[];
      stateAverage: any;
      USAAverage: any;
    };
    status: {
      isLoading: boolean;
      error: any;
    };
  };
}

export function Overview({ data }: props) {
  const { pay } = data.entities;

  return (
    <div className="grid grid-cols-12 gap-4 mb-16">
      <div className="col-span-12 md:col-span-6">
        <AveragePay data={data} />
      </div>

      <div className="col-span-12 md:col-span-6">
        <StatePay data={data} />
      </div>

      <div className="p-4 rounded-xl dark:bg-dark-800 col-span-12">
        <Heading className="mb-6">
          See how much experience affects your pay
        </Heading>
        <PayByExpChart data={pay} />
      </div>

      <div className="col-span-12 rounded-xl bg-dark-800 hidden sm:block">
        <div
          className="rounded-xl w-full bg-blue-400 p-4 bg-right bg-no-repeat bg-cover py-8"
          style={{
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Flanding%2Fbackgrounds%2Fcoming_soon_bg.jpg?alt=media&token=61677f89-14d6-48e8-b1d0-e47a28cfc08a)`,
          }}
        >
          <Text className="text-4xl font-semibold mb-2 sm:w-2/3">
            Are you finding SkilledPay useful?
          </Text>
          <Text className="text-lg sm:w-2/3">
            Our mission is to make skilled trades pay accessible to everyone. We
            are adding more insights and new features every week.
            <Emoji symbol="✨" label="sparkle" className="mx-1 text-xl" />
            Come back and see what's new
          </Text>
        </div>
      </div>

      <div className="col-span-12 rounded-xl bg-dark-800 sm:hidden">
        <div
          className="rounded-xl w-full bg-blue-400 p-4 bg-center bg-no-repeat bg-cover py-12"
          style={{
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/skilledpay.appspot.com/o/assets%2Flanding%2Fbackgrounds%2Fcoming_soon_bg_mobile.jpg?alt=media&token=8f5cf261-ac67-4d70-bdc0-d598acef3c98)`,
          }}
        >
          <Text className="text-4xl font-semibold mb-2">
            Are you finding SkilledPay useful?
          </Text>
          <Text className="text-lg">
            Our mission is to make skilled trades pay accessible to everyone. We
            are adding more insights and new features every week.
            <Emoji symbol="✨" label="sparkle" className="mx-1 text-xl" />
            Come back and see what's new
          </Text>
        </div>
      </div>
    </div>
  );
}
