import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Store } from './lib/store';
import { Home, HomeNav } from './features/home';
import { Navbar as PayNav, PayContainer } from './features/pay';
import { DrawerDispatcher } from './features/drawer';
// import { ScrollToTop } from './elements';
import { Layout } from './components';

import 'react-tiny-fab/dist/styles.css';
import './App.css';

function App() {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Store>
      <Router>
        {/* <ScrollToTop /> */}

        <div className="app dark:text-gray-200 dark:bg-dark-900">
          <Layout
            heading={
              <Switch>
                <Route path="/pay">
                  <PayNav />
                </Route>
                <Route path="/">
                  <HomeNav />
                </Route>
              </Switch>
            }
            footer={null}
          >
            <Switch>
              <Route path="/pay">
                <PayContainer />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Layout>

          <Switch>{/* Put drawer components here */}</Switch>

          <DrawerDispatcher />
        </div>
      </Router>
    </Store>
  );
}

export default App;
