import React from 'react';

import { trades } from '../../../lib/utils/trades';
import { TradePill } from '../../../components';

type props = {
  active: string;
  onSelect: (item: any) => void;
  onClear: () => void;
  expandable?: boolean;
  isExpanded?: boolean;
};

export function TradeList({
  active,
  onSelect,
  onClear,
  expandable = false,
  isExpanded,
}: props) {
  const trade = trades.find((t) => t.id === active);
  const [category, setCategory] = React.useState(
    trade?.construction ? 'construction' : 'industrial'
  );

  const activeTabStyles = 'bg-dark-200';
  const inactiveTabStyles = 'bg-dark-800 hover:bg-dark-200';

  const handleCategoryUpdate = (cat: 'industrial' | 'construction') =>
    setCategory(cat);

  const getTradePillMap = () => {
    const map = trades
      .filter((trade) => {
        if (category === 'industrial') {
          return trade.industrial;
        } else {
          return trade.construction;
        }
      })
      .sort((a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      })
      .map((trade) => (
        <TradePill
          id={trade.id}
          name={trade.name}
          url={trade.imageURL}
          activeTrade={active}
          onSelect={onSelect}
          onClear={onClear}
          key={trade.id}
        />
      ));

    return map;
  };

  return (
    <div>
      <div className="my-4 flex flex-row">
        <button
          className={`flex-1 inline-flex items-center justify-center rounded-l-md px-2 py-1 text-sm font-normal border border-dark-100 ${
            category === 'industrial' ? activeTabStyles : inactiveTabStyles
          }`}
          onClick={() => handleCategoryUpdate('industrial')}
        >
          Industrial
        </button>
        <button
          className={`flex-1 inline-flex items-center justify-center rounded-r-md px-2 py-1 text-sm font-normal border border-dark-100 ${
            category === 'construction' ? activeTabStyles : inactiveTabStyles
          }`}
          onClick={() => handleCategoryUpdate('construction')}
        >
          Construction
        </button>
      </div>

      <ul
        className={`mt-4 flex flex-row flex-wrap ${
          expandable && !isExpanded && 'h-72 overflow-y-hidden'
        } ${expandable && isExpanded && 'h-full overflow-y-auto'}`}
      >
        {getTradePillMap()}
      </ul>
    </div>
  );
}
