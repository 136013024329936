import { stateType, actionType } from './type';

export const reducer = (state: stateType, action: actionType) => {
  switch (action.type) {
    case 'drawer/open':
      return {
        ...state,
        drawer: {
          isOpen: action.payload.isOpen,
        },
      };
    case 'drawer/close':
      return {
        ...state,
        drawer: {
          isOpen: action.payload.isOpen,
        },
      };
    case 'query/setLocation':
      return {
        ...state,
        query: {
          ...state.query,
          location: {
            ...state.query.location,
            ...action.payload,
          },
        },
      };
    case 'query/setTrade':
      return {
        ...state,
        query: {
          ...state.query,
          trade: action.payload,
        },
      };
    case 'query/setRadius':
      return {
        ...state,
        query: {
          ...state.query,
          radius: action.payload,
        },
      };
    default:
      return state;
  }
};
