import React from 'react';
import { Link } from 'react-router-dom';

import { trades, store } from '../../../lib/';
import { Container } from '../../../components';
import logo from '../../../lib/assets/logo/skilledpay_logo_icon_only.png';

export function Navbar() {
  const { state } = React.useContext(store);
  const trade = trades.find((t) => t.id === state.query.trade);

  let tradeName = trade?.name || '';

  if (tradeName.length > 12) {
    tradeName = tradeName.substring(0, 15) + '...';
  }

  return (
    <nav className="top-0 left-0 bg-white dark:bg-dark-900 fixed z-40 w-full shadow-sm border-b border-dark-700">
      <Container>
        <div
          className="mx-auto flex items-center h-16"
          style={{ maxWidth: '1280px' }}
        >
          <div className="flex flex-1 justify-start mr-auto">
            <Link to="/" className="inline">
              <img className="h-5" src={logo} alt="Workflow" />
            </Link>
          </div>

          <div className="flex flex-1 ml-auto justify-end"></div>
        </div>
      </Container>
    </nav>
  );
}
