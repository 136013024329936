import React from 'react';
import { XCircle } from 'react-feather';

import { SearchMapInput } from '../common/SearchMapInput';
import { Drawer, Text, PrimaryButton } from '../../components';

interface props {
  isOpen: boolean;
  activeLocation: any;
  targetScrollRef: any;
  handleClose: () => void;
  handleSaveAndClose: () => void;
  handleSetActiveLocation: (location: any) => void;
}

export function QueryLocation({
  handleClose,
  isOpen,
  handleSaveAndClose,
  targetScrollRef,
  handleSetActiveLocation,
  activeLocation,
}: props) {
  const header = (
    <div className="p-4 bg-dark-900 rounded-t-2xl flex flex-row border-b border-dark-400">
      <Text className="text-xl font-semibold dark:text-gray-300">
        Where are you based?
      </Text>
      <XCircle
        color="#6B7280"
        size={24}
        className="ml-auto cursor-pointer"
        onClick={handleClose}
      />
    </div>
  );

  return (
    <Drawer
      isOpen={isOpen}
      header={header}
      footer={
        <div className="p-4">
          <PrimaryButton className="w-full" onClick={handleSaveAndClose}>
            Save
          </PrimaryButton>
        </div>
      }
      onClose={handleClose}
      targetScrollRef={targetScrollRef}
    >
      <Text className="text-gray-300 p-4">Please enter an address</Text>
      <div className="px-4">
        <SearchMapInput
          onSelect={handleSetActiveLocation}
          location={activeLocation}
        />
      </div>

      <div className="p-4">
        <div
          className="bg-gray-500 rounded-lg w-full"
          style={{
            height: '320px',
            backgroundImage: `url("https://maps.googleapis.com/maps/api/staticmap?center=${activeLocation.lat},${activeLocation.long}&zoom=13&scale=2&size=600x300&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_API}&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x4F46E5%7Clabel:%7C${activeLocation.lat},${activeLocation.long}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </div>
    </Drawer>
  );
}
