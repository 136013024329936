import React from 'react';

type props = {
  children: React.ReactNode;
  heading: React.ReactNode;
  footer: React.ReactNode;
};

export function Layout({ children, heading, footer }: props) {
  return (
    <>
      {heading}

      <main className="pt-16 mx-auto" style={{ minHeight: '100vh' }}>
        {children}
      </main>

      {footer}
    </>
  );
}
