import React from 'react';
import { Globe, PieChart, ChevronDown } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { Menu } from '@headlessui/react';

import { store } from '../../lib/store';
import { TradeList } from '../common';
import { Container, Dropdown } from '../../components';
import {
  Heading,
  HeroTitle,
  HeroSubtitle,
  LightButton,
  Button,
} from '../../components';

export function Home() {
  const { state, dispatch } = React.useContext(store);
  const [selectedTrade, setSelectedTrade] = React.useState('');
  const [isExpanded, setExpanded] = React.useState(false);
  const history = useHistory();

  const setTrade = (trade: string) => {
    dispatch({ type: 'query/setTrade', payload: trade });
    setSelectedTrade(trade);
  };

  const setRadius = (radius: number) =>
    dispatch({ type: 'query/setRadius', payload: radius });

  const clearTrade = () => {
    dispatch({ type: 'query/setRadius', payload: '' });
  };

  const bottomPaddingIfSelected =
    selectedTrade && state.query.location.lat && state.query.location.long
      ? 'pb-40 lg:pb-20'
      : null;

  return (
    <Container>
      <div
        className={`mt-8 pb-20 grid grid-cols-1 lg:grid-cols-2 gap-16 ${bottomPaddingIfSelected}`}
      >
        {/* Left Hero Panel */}
        <div className="">
          <HeroTitle>How much are your skills worth?</HeroTitle>

          <HeroSubtitle className="mt-8 leading-normal">
            Browse 10,000+ real job offers to get insights about your pay.
            SkilledPay is the first and only career planner designed for
            industrial and construction skilled trades. Explore our tools and
            get paid more today!
          </HeroSubtitle>
        </div>

        {/* Right Form Panel */}
        <div className="rounded-xl py-6 lg:py-8 dark:bg-dark-800">
          <div className="px-6 lg:px-8">
            <HeroSubtitle className="mb-4">Where are you based?</HeroSubtitle>
            <LightButton
              size="lg"
              pre={<Globe size={24} strokeWidth={1.5} />}
              onClick={() =>
                dispatch({
                  type: 'drawer/open',
                  payload: { isOpen: 'query/location' },
                })
              }
            >
              {state.query.location.formatted_address}
            </LightButton>
          </div>

          <div className="mt-12 px-6 lg:px-8">
            <HeroSubtitle>
              <span className="mr-2">Browse within</span>
              <Dropdown
                trigger={
                  <span className="text-gray-100 font-medium border-b-2 cursor-pointer hover:opacity-90">
                    {state.query.radius} miles
                    <ChevronDown
                      width={32}
                      strokeWidth={3}
                      color="white"
                      style={{ display: 'inline' }}
                    />
                  </span>
                }
              >
                <Menu.Items className="absolute right-0 mt-2 origin-top-right bg-dark-100 rounded-md shadow-lg outline-none w-max">
                  <Menu.Item
                    className="px-4 py-3 my-1 hover:bg-blue-600"
                    onClick={() => setRadius(25)}
                  >
                    <Heading clickable>25 miles</Heading>
                  </Menu.Item>
                  <Menu.Item
                    className="px-4 py-3 my-1 hover:bg-blue-600"
                    onClick={() => setRadius(50)}
                  >
                    <Heading clickable>50 miles</Heading>
                  </Menu.Item>
                  <Menu.Item
                    className="px-4 py-3 my-1 hover:bg-blue-600"
                    onClick={() => setRadius(75)}
                  >
                    <Heading clickable>75 miles</Heading>
                  </Menu.Item>
                  <Menu.Item
                    className="px-4 py-3 my-1 hover:bg-blue-600"
                    onClick={() => setRadius(100)}
                  >
                    <Heading clickable>100 miles</Heading>
                  </Menu.Item>
                </Menu.Items>
              </Dropdown>
            </HeroSubtitle>
          </div>

          <div className="mt-12 px-6 lg:px-8">
            <HeroSubtitle>Which trade do you specialze in?</HeroSubtitle>
            <TradeList
              active={selectedTrade}
              onSelect={setTrade}
              onClear={clearTrade}
              expandable
              isExpanded={isExpanded}
            />
          </div>

          <div
            className={`bg-dark-800 relative h-8 flex flex-row items-end justify-center ${
              isExpanded ? 'shadow-none' : 'shadow-overflow'
            }`}
          >
            {isExpanded ? (
              <button
                className="text-sm font-medium text-gray-200 hover:text-blue-700"
                onClick={() => setExpanded(false)}
              >
                Show Less
              </button>
            ) : (
              <button
                className="text-sm font-medium text-gray-200 hover:text-blue-700"
                onClick={() => setExpanded(true)}
              >
                Show More
              </button>
            )}
          </div>
        </div>

        {selectedTrade &&
          state.query.location.lat &&
          state.query.location.long && (
            <div className="fixed bottom-0 left-0 w-full z-30 bg-blue-600 flex rounded-t-xl flex-wrap">
              <div className="m-4 flex flex-row items-center flex-grow justify-center">
                <div className="bg-blue-700 p-2 rounded-lg">
                  <PieChart size={24} strokeWidth={1.5} color="white" />
                </div>

                <Heading className="ml-4">
                  Ready? Let's see how skilled pros are compensated in your area
                </Heading>
              </div>

              <Button
                className="mx-4 mt-2 mb-6 md:m-4 dark:bg-gray-100 dark:text-blue-600 hover:opacity-90 flex-none order-last flex-grow md:flex-initial"
                onClick={() => {
                  history.push('/pay');
                  window.scrollTo(0, 0);
                }}
              >
                Find Results
              </Button>
            </div>
          )}
      </div>
    </Container>
  );
}
