import React from 'react';
import { XCircle } from 'react-feather';

import { TradeList } from '../common';
import { Drawer, Text, PrimaryButton } from '../../components';

type props = {
  isOpen: boolean;
  selectedTrade: any;
  targetScrollRef: any;
  handleClose: () => void;
  handleSave: () => void;
  handleClear: () => void;
  handleSelect: (trade: string) => void;
};

export function PickTrade({
  isOpen,
  selectedTrade,
  targetScrollRef,
  handleClose,
  handleSave,
  handleClear,
  handleSelect,
}: props) {
  const header = (
    <div className="p-4 bg-dark-900 rounded-t-2xl flex flex-row border-b border-dark-400">
      <Text className="text-xl font-semibold dark:text-gray-300">
        Select a trade
      </Text>
      <XCircle
        color="#6B7280"
        size={24}
        className="ml-auto cursor-pointer"
        onClick={handleClose}
      />
    </div>
  );

  return (
    <Drawer
      isOpen={isOpen}
      header={header}
      footer={
        <div className="w-full p-4">
          <PrimaryButton size="lg" className="w-full" onClick={handleSave}>
            Save
          </PrimaryButton>
        </div>
      }
      onClose={handleClose}
      targetScrollRef={targetScrollRef}
    >
      <div className="m-4">
        <TradeList
          active={selectedTrade}
          onSelect={handleSelect}
          onClear={handleClear}
        />
      </div>
    </Drawer>
  );
}
