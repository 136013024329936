import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import { Text } from '../../../components';

type props = {
  onSelect: (location: any) => void;
  location: any;
};

export const Container = ({ onSelect, location }: props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      types: ['address'],
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }: { description: any }) => async () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    try {
      const results = await getGeocode({ address: description });
      const result = results[0];
      const address_components = result.address_components;
      const locality =
        address_components.find(
          (c: any) =>
            c.types.includes('locality') || c.types.includes('sublocality')
        )?.long_name || '';
      const administrative_area_level_1 =
        address_components.find((c: any) =>
          c.types.includes('administrative_area_level_1')
        )?.short_name || '';
      const postal_code =
        address_components.find((c: any) => c.types.includes('postal_code'))
          ?.long_name || '';

      const { lat, lng } = await getLatLng(results[0]);

      const location = {
        id: result.place_id,
        lat: lat.toString(),
        long: lng.toString(),
        formatted_address: result.formatted_address,
        locality,
        administrative_area_level_1,
        postal_code,
      };

      onSelect(location);
    } catch (err) {
      console.log(err.message);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          className="px-4 py-2 hover:bg-dark-600"
          onClick={handleSelect(suggestion)}
        >
          <Text clickable className="font-semibold text-gray-100">
            {main_text}
          </Text>
          <Text clickable className="text-sm text-gray-500">
            {secondary_text}
          </Text>
        </li>
      );
    });

  return (
    <div ref={ref} className="relative">
      <input
        id="query_location"
        name="query_location"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        required
        className={`appearance-none relative block w-full px-4 py-3 dark:bg-dark-800 placeholder-gray-500 text-normal font-normal text-gray-100 rounded-md focus:outline-none ${
          status === 'OK' && 'rounded-b-none border-b border-dark-400'
        }`}
        placeholder={`${location.formatted_address}`}
        style={{ caretColor: 'white' }}
      />

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <div className="absolute w-full rounded-b-md bg-dark-800 shadow-lg">
          <ul>{renderSuggestions()}</ul>
        </div>
      )}
    </div>
  );
};
