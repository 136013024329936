import React from 'react';
import { useWindowDimensions } from '../hooks';

type props = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  header: React.ReactNode;
  footer: React.ReactNode;
  targetScrollRef: any;
};

export function Drawer({
  children,
  isOpen,
  header,
  footer,
  onClose,
  targetScrollRef,
}: props) {
  const { height } = useWindowDimensions();

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  const content = (
    <section
      className="drawer fixed bottom-0 max-w-full flex z-50 px-0 lg:right-0 lg:w-40"
      style={{ height: 0.85 * height }}
      aria-labelledby="slide-over-heading"
    >
      <div className="relative w-screen w-full h-full">
        <div className="h-full flex flex-col bg-white dark:bg-dark-900 shadow-xl rounded-t-2xl lg:rounded-none lg:rounded-l-2xl">
          {/* Heading Section */}
          {header}

          {/* Main Content */}
          <div
            className="mb-12 overflow-y-auto touch-scroll h-full"
            ref={targetScrollRef}
          >
            {children}
          </div>

          {/* Footer */}
          <div className="absolute bottom-0 left-0 bg-white dark:bg-dark-900 w-full shadow-invert rounded-bl-2xl">
            {footer}
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <div className="fixed inset-0 overflow-hidden z-40 transition-all">
      <div className="absolute inset-0 transition-opacity" aria-hidden="true">
        <div
          className="absolute inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={handleClose}
        ></div>

        {content}
      </div>
    </div>
  );
}
