import React from 'react';
import { Avatar } from '.';

type props = {
  name: string;
  url: string;
  id: string;
  activeTrade: string;
  onSelect: (key: string) => void;
  onClear: () => void;
  [x: string]: any;
};

export function TradePill({
  name,
  id,
  activeTrade,
  onSelect,
  onClear,
  url,
  ...rest
}: props) {
  const handleClick = () => {
    if (activeTrade === id) {
      onClear();
    } else {
      onSelect(id);
    }
  };

  const activeStyles =
    id === activeTrade ? 'dark:bg-blue-600' : 'dark:bg-dark-400';

  return (
    <li
      className={`py-1 pl-1 pr-2 mb-4 mr-4 rounded-full flex flex-row items-center text-center cursor-pointer hover:opacity-90 ${activeStyles}`}
      onClick={handleClick}
      {...rest}
    >
      <Avatar url={url} size="sm" alt="" className="inline" />
      <span className="inline font-medium ml-2 dark:text-gray-100 text-normal leading-non">
        {name}
      </span>
    </li>
  );
}
