import React from 'react';

type props = {
  children: React.ReactNode;
  className?: string;
  [x: string]: any;
};

export function Container({ className, children, ...rest }: props) {
  return (
    <div className={`px-4 lg:px-6 ${className}`}>
      <div className="mx-auto" style={{ maxWidth: '1280px' }} {...rest}>
        {children}
      </div>
    </div>
  );
}
