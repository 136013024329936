import { Bar } from 'react-chartjs-2';

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
          callback: function (value: any) {
            return '$' + value;
          },
          fontColor: '#6B7280',
          fontSize: 14,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontColor: '#6B7280',
          fontSize: 14,
        },
        scaleLabel: {
          display: true,
          labelString: 'Years of Experience',
          fontColor: '#6B7280',
          fontSize: 16,
        },
      },
    ],
  },
  maintainAspectRatio: false,
  legend: false,
  tooltips: {
    enabled: false,
  },
};

export const PayByExpChart = ({ data }: { data: any[] }) => {
  const averages = [
    { sum: 0, count: 0 },
    { sum: 0, count: 0 },
    { sum: 0, count: 0 },
    { sum: 0, count: 0 },
    { sum: 0, count: 0 },
    { sum: 0, count: 0 },
  ];

  for (let i = 0; i < data.length; i++) {
    const current = data[i];
    const salary = current.averageSalary;
    const exp = current.yearsExp;

    if (exp === 0) {
      averages[5].sum += salary;
      averages[5].count++;
    } else if (exp < 1) {
      averages[0].sum += salary;
      averages[0].count++;
    } else if (exp >= 1 && exp <= 3) {
      averages[1].sum += salary;
      averages[1].count++;
    } else if (exp >= 3 && exp <= 5) {
      averages[2].sum += salary;
      averages[2].count++;
    } else if (exp >= 5 && exp <= 10) {
      averages[3].sum += salary;
      averages[3].count++;
    } else if (exp >= 10) {
      averages[4].sum += salary;
      averages[4].count++;
    }
  }

  const averageSalaryByExp = averages.map((avg) => {
    if (avg.count) {
      return Math.round((avg.sum / avg.count) * 10) / 10;
    }

    return 0;
  });

  const dataset = {
    labels: ['<1', '1-3', '3-5', '5-10', '10+', 'N/A'],
    datasets: [
      {
        label: 'Hourly Pay',
        barPercentage: 1,
        data: averageSalaryByExp,
        backgroundColor: [
          'rgba(37, 99, 235, 0.2)',
          'rgba(37, 99, 235, 0.2)',
          'rgba(37, 99, 235, 0.2)',
          'rgba(37, 99, 235, 0.2)',
          'rgba(37, 99, 235, 0.2)',
          'rgba(37, 99, 235, 0.2)',
        ],
        hoverBackgroundColor: [
          '#2563EB',
          '#2563EB',
          '#2563EB',
          '#2563EB',
          '#2563EB',
          '#2563EB',
        ],
        borderColor: [
          '#2563EB',
          '#2563EB',
          '#2563EB',
          '#2563EB',
          '#2563EB',
          '#2563EB',
        ],
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="relative" style={{ minHeight: '320px' }}>
      <Bar
        data={dataset}
        options={options}
        getElementAtEvent={(e) => console.log(e)}
      />
    </div>
  );
};
