import React from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { store } from '../../lib/store';
import { QueryLocation } from './PickLocation';

type props = {
  isOpen: boolean;
  onClose: () => void;
};

export function PickLocationContainer({ isOpen, onClose }: props) {
  const { state, dispatch } = React.useContext(store);
  const [activeLocation, setActiveLocation] = React.useState(
    state.query.location
  );

  const targetScrollRef = React.createRef<any>();
  React.useEffect(() => {
    let targetScrollEl: any = null;
    targetScrollEl = targetScrollRef.current;

    if (isOpen) {
      disableBodyScroll(targetScrollEl, {
        reserveScrollBarGap: true,
      });
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isOpen, targetScrollRef]);

  const handleSetActiveLocation = (location: any) => {
    setActiveLocation(location);
  };

  const handleSaveAndClose = () => {
    dispatch({ type: 'query/setLocation', payload: activeLocation });
    handleClose();
  };

  const handleClose = () => {
    clearAllBodyScrollLocks();
    onClose();
  };

  const props = {
    isOpen,
    activeLocation,
    targetScrollRef,
    handleClose,
    handleSaveAndClose,
    handleSetActiveLocation,
  };

  return <QueryLocation {...props} />;
}
