// @ts-nocheck
import React, { createContext, useReducer } from 'react';
import { reducer } from './reducer';
import { stateType, actionType } from './type';

const initialState: stateType = {
  drawer: {
    isOpen: '',
  },
  query: {
    // Local cache of geolocation data from google maps
    location: {
      id: 'ChIJOwg_06VPwokRYv534QaPC8g',
      lat: '40.7127753',
      long: '-74.0059728',
      formatted_address: 'New York, NY, USA',
      locality: 'New York',
      administrative_area_level_1: 'NY',
      postal_code: '',
    },
    radius: 50,
    trade: '9d78J5vvJiIcf4TQhvmZ',
  },
  error: null,
};

export const store = createContext({
  state: initialState,
  dispatch: (_: actionType) => {},
});
const { Provider } = store;

export const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
