import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { PlusCircle } from 'react-feather';

import { Text, Heading, Card, Emoji } from '../../../components';
import { Submission } from './Submission';

interface props {
  data: {
    entities: {
      pay: any[];
      stateAverage: any;
      USAAverage: any;
    };
    status: {
      isLoading: boolean;
      error: any;
    };
  };
}

export function Details({ data }: props) {
  const { pay } = data.entities;

  if (data.status.isLoading) {
    return (
      <Card>
        <div className="">
          <Heading className="mb-2">Recenty Added Salaries</Heading>
          <Skeleton count={2} />
        </div>
      </Card>
    );
  }

  if (!pay.length) {
    return (
      <Card>
        <div>
          <Heading className="mb-2">Recenty Added Salaries</Heading>
          <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-4">
            <Emoji symbol="💰" label="sparkle" className="mr-1 text-xl" />
            We don't have enough user submitted salaries in your area to show.
            Try searching for another location or increasing the search radius.
          </Text>
        </div>

        <ul className="dark:bg-dark-800 rounded-xl border-b border-dark-700"></ul>
      </Card>
    );
  }

  return (
    <Card>
      <div className="">
        <Heading className="mb-2">Recenty Added Salaries</Heading>
        <Text className="text-base font-normal text-gray-800 dark:text-gray-300 mb-4">
          <Emoji symbol="💰" label="sparkle" className="mr-1 text-xl" />
          Our insights are based on salaries submitted by real users. Here are
          the latest submissions
        </Text>
      </div>

      <ul className="dark:bg-dark-800 rounded-xl border-b border-dark-700">
        {pay.map((item) => (
          <Submission data={item} />
        ))}
      </ul>
    </Card>
  );
}

export function CallToAction() {
  return (
    <div className="mb-8 flex flex-row items-center p-4 rounded-xl border dark:border-dark-400 dark:bg-blue-600 cursor-pointer hover:opacity-90">
      <PlusCircle
        size={24}
        strokeWidth={1.5}
        color="#F3F4F6"
        className="mr-2"
      />

      <Text
        clickable
        className="text-sm lg:text-base font-normal text-gray-800 leading-normal wrap-body dark:text-gray-100"
      >
        Feeling generous? Share your pay with us
      </Text>
    </div>
  );
}
