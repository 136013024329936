import React from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { store } from '../../lib/store';
import { PickTrade } from './PickTrade';

type props = {
  isOpen: boolean;
  onClose: () => void;
};

export function PickTradeContainer({ isOpen, onClose }: props) {
  const { state, dispatch } = React.useContext(store);
  const [selectedTrade, setSelectedTrade] = React.useState(state.query.trade);

  const targetScrollRef = React.createRef<any>();
  React.useEffect(() => {
    let targetScrollEl: any = null;
    targetScrollEl = targetScrollRef.current;

    if (isOpen) {
      disableBodyScroll(targetScrollEl, {
        reserveScrollBarGap: true,
      });
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isOpen, targetScrollRef]);

  const handleClose = () => {
    clearAllBodyScrollLocks();
    onClose();
  };

  const handleSelect = (trade: string) => {
    setSelectedTrade(trade);
  };

  const handleClear = () => {};

  const handleSave = () => {
    dispatch({ type: 'query/setTrade', payload: selectedTrade });
    handleClose();
  };

  const props = {
    isOpen,
    selectedTrade,
    targetScrollRef,
    handleClose,
    handleSave,
    handleClear,
    handleSelect,
  };

  return <PickTrade {...props} />;
}
