import React from 'react';

import { store } from '../../lib/store';
import { PickLocationContainer } from './PickLocationContainer';
import { PickTradeContainer } from './PickTradeContainer';

export function DrawerDispatcher() {
  const { state, dispatch } = React.useContext(store);
  const isOpen = state.drawer.isOpen ? true : false;

  const closeDrawer = () => dispatch({ type: 'drawer/close', payload: {} });

  switch (state.drawer.isOpen) {
    case 'query/location':
      return <PickLocationContainer isOpen={isOpen} onClose={closeDrawer} />;
    case 'query/trade':
      return <PickTradeContainer isOpen={isOpen} onClose={closeDrawer} />;
    default:
      return null;
  }
}
