import React from 'react';
import icon from './verified.png';

import { toCurrency } from '../../../lib';
import { Text } from '../../../components';

interface props {
  data: any;
}

export function Submission({ data }: props) {
  const location = `${data.location.city}, ${data.location.state}`;
  const pay = toCurrency(data.averageSalary, 1);

  return (
    <div className="py-4 border-t border-dark-700 flex flex-row items-center">
      <div>
        <li className="text-gray-200">
          {data.title}
          <img src={icon} className="ml-1 w-5 inline" alt="validated icon" />
        </li>
        <Text className="leading-none text-sm text-gray-400">{location}</Text>
      </div>

      <div className="ml-auto">
        <span className="text-gray-200 text-lg font-semibold">${pay}</span>
        <span className="ml-1 text-xs text-gray-400">/hr</span>
      </div>
    </div>
  );
}
